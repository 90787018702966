.map-info-container {
    max-width: 600px;
    max-height: 100%;
    overflow: auto;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.map-info-container::-webkit-scrollbar {
    display: none;
}

.map-info-title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.map-data {
    position: relative;
    /* height: 70%; */
    overflow: auto;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
}

.map-data::-webkit-scrollbar {
    display: none;
}

.map-detail {
    margin-bottom: 10px;
    line-height: 1.5;
    color: #555;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.tag {
    background-color: #007bff;
    color: rgb(50, 191, 209);
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
}

.loading {
    text-align: center;
}
