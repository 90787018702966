.edit-map-view-heatmap {
  position: relative;
  background-color: gray;
  height: 100%;
  width: 80.25%;
  /* margin-left: 20%; */
  left: 19.75%;
  /* right: 0; */
  z-index: 1000;
}

.undoRedoContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000; /* Ensure it's always at the front */
  display: flex;
  gap: 10px; /* Adds a little space between buttons */
}

.edit-bottom-right-comment {
  position: absolute;
  z-index: 1000;
  bottom: 2vh;
  right: 2vw;
  background-color: white;
  padding: 5px;
  border-radius: 25px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
}

.component-bottom-right-copy {
  position: absolute;
  z-index: 1000;
  bottom: 2vh;
  right: 5.5vw;
  background-color: white;
  padding: 5px;
  border-radius: 25px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
}

/* .edit-bottom-right-comment {
  position: absolute;
  z-index: 1000;
  bottom: 4vh;
  right: 2vw;
  background-color: white;
  padding: 5px;
  border-radius: 25px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
} */