.profile-center {
  display: flex;
  /* display: ; */
  justify-content: center;
  max-height: 100%;
  overflow: auto;
}

.profile-center::-webkit-scrollbar {
  display: none;
}

.username-label {
  font-weight: bold;
  margin-right: 8px;
}
.username-section {
  position: relative;
  display: grid;
  width: fit-content;
  background-color: #f2f4f8;
  /* background-color: red; */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  justify-content: center;
  /* max-width: 300px;  */
  /* margin: auto; */
}

.display-username {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.username-text {
  /* font-size: 20px;  */
  align-items: center;
  color: #333; /* Dark text color for contrast */
  /* font-weight: bold;  */
}

.edit-username-input {
  flex-grow: 1; /* Input field takes up available space */
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc; /* Subtle border for input */
}

.icon-check,
.icon-close,
.icon-edit {
  cursor: pointer;
  color: #4a90e2; /* Blue color for icons */
  margin-left: 10px;
}

.icon-check:hover,
.icon-close:hover,
.icon-edit:hover {
  color: #3c7dcf; /* Slightly darker color on hover */
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f4f4f4;
}

.bottom-box-line {
  display: flex;
  justify-content: space-between; /* This spreads out the items */
  align-items: center; /* Aligns items vertically in the center */
  width: 100%; /* Container takes full width */
  position: absolute;
  bottom: 0;
}

.log-out-box {
  margin-right: auto; /* This pushes the component to the center */
  margin-left: auto;
}
