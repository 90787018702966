/* FileLoader.css */
.loader-container {
  border: 2px dashed #ccc;
  padding: 20px;
  max-width: 300px;
  margin: 0 auto;
  position: relative;
}

.file-uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.project-name-input {
  margin-bottom: 50px;
}

.file-uploader-buttons {
  display: flex;
  justify-content: space-evenly;
  padding-top: 60px;
}

/* .submit-button, .cancel-button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.cancel-button {
    background-color: #dc3545;
} */

.file-input {
  display: none;
}

.drop-zone {
  text-align: center;
  cursor: pointer;
}

.drop-zone span {
  color: blue;
  text-decoration: underline;
}

.file-loader-prompt {
  text-align: center;
}

.structure-of-map {
  /* height: 100vh;
  width: 100vw; */
  height: 100vh;
  width: 100%;
}

.home-page {
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* Allows click events to pass through  */
  pointer-events: none;
  z-index: 500;

  display: flex;
  /* Vertically centers the child element */
  align-items: center;
  /* Horizontally centers the child element */
  justify-content: center;
}

.home-title {
  font-family: "Arial", cursive;
  font-size: 7vw;
  font-style: italic;
  font-weight: bold;
  color: #f35d74;
  text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25); /* 50% black */
}

.home-icon {
  font-family: "Arial", cursive;
  font-size: 1vw min(1vw);
  font-size: min(1vw);
  font-style: italic;
  font-weight: bold;
  color: #f35d74;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25); /* 50% black */
}

.leaflet-control-zoom {
  /* position: absolute !important; Override the default position */
  top: -8vh !important; /* Adjust this value to move it vertically */
  left: 1vh !important; /* Adjust this value to move it horizontally */
}

.shadow-icon {
  box-shadow: 5px 3px 10px 0px rgba(0, 0, 0, 0.75);
}

.component-top-left {
  position: absolute;
  top: 2vh;
  left: 2vh;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  border-radius: 25px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
}

.component-top-left-search {
  position: absolute;
  top: 2vh;
  left: 2vh;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  border-radius: 25px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
}

.component-top-left-content {
  position: absolute;
  top: 2vh;
  left: 2vh;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  border-radius: 25px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
}

.component-top-right {
  position: absolute;
  top: 2vh;
  right: 2vh;
  z-index: 1000;
  background-color: white;
  padding: -10px;
  border-radius: 25px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
}

.component-bottom-left {
  position: absolute;
  bottom: 2vh;
  left: 2vh;
  z-index: 1000;
  border-radius: 25px;
  /* background-color: white; */
  /* box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);     */
}

.component-bottom-right {
  position: absolute;
  bottom: 4vh;
  right: 2%;
  z-index: 1000;
  width: auto;
  /* padding: 5px; */
  border-radius: 25px;
}

.component-bottom-right-comment {
  position: relative;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  border-radius: 25px;
  right: 10px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
}
.component-bottom-right-add {
  z-index: 1000;
  background-color: white;
  border-radius: 25px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
}

.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  background-color: white;
  width: 60vw;
  height: 70vh;
  min-height: 500px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.no-interaction {
  pointer-events: none;
}

.greyed-out {
  color: grey;
}

.admin-page {
  height: 100vh;
  width: 100vw;
  background-color: #ffc3d41c;
  display: flex;
  /* Vertically centers the child element */
  /* align-items: center; */
  /* Horizontally centers the child element */
  justify-content: center;
}

.admin-portal-table {
  width: 50vw;
  padding: 7px;
  margin: 5px;
  background-color: white;
  border-radius: 8px;
  display: auto;
}
